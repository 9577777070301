/**
 * Todo: Populate real locations/ dates
 * Get Card colors from backend
 */

class Credential {
    printIsBulk = false;
    octoChecked = false;
    escortChecked = false;
    person = null;
    credPicsDir = '../../cred_pics/';
    credentialPicHolder = $('#credential_print_modal_cred_pic');
    credentialNum = null;
    eventNum = null;
    canvas;


    init() {
        this.eventId = $('#event_attributes').data('event-id');
        this.credentialPrintModal = $('#credential_print_modal');
        this.credentialPrintModalClose = $('#credential_print_modal_close');
        this.credentialPrintModalPersonName = $('#credential_print_modal_person_name');
        this.rotatingColorSelect = $('#rotating_color_select');
        this.canvas = document.createElement('canvas');

        $('body').on('click', '.printCredential', credential.credentialPrintButtonOnClick);
        $('body').on('click', '#bulkPrintButton', credential.bulkPrintOnClick);
        $('body').on('change', '#select-all-print', credential.selectAllPrint);


        this.credentialPrintModalClose.on('click', credential.credentialPrintCloseOnClick);

        const cred = this;
        $('#credential_print_modal_cb_octagon').change(function () {
            let checked = $('#credential_print_modal_cb_octagon').is(":checked");
            credential.cardDetails.octagon = checked;
            cred.createCanvas(credential.cardDetails, () => {
            });
        });
        $('#credential_print_modal_cb_escort').change(function () {
            let checked = $('#credential_print_modal_cb_escort').is(":checked");
            credential.cardDetails.escort = checked;
            cred.createCanvas(credential.cardDetails, () => {
            });
        });

        this.rotatingColorSelect.on('change', credential.rotatingColorSelectOnChange);
    };

    rotatingColorSelectOnChange() {
        $.ajax({
            url: '/events/' + credential.eventId + '/ajax_update_credential_temp_color',
            data: {credential_temp_color: $(this).val()},
            method: 'POST',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    toastr.success('Rotating Color Updated');
                    setTimeout(function () {
                        location.reload();
                    }, 500);
                } else {
                    toastr.error('Unable To Update Rotating Color.');
                }
                ;
            },
            error: function (data) {
                toastr.error('Unable To Update Rotating Color.');
            }
        });
    };

    credentialPrintButtonOnClick() {
        let eventJson = $(this).data('event');
        let personId = $(this).data('person-id');
        let eventPersonId = $(this).data('event-person-id');
        let credentialPicExt = $(this).data('person-credential-pic-ext');
        let credentialTypeRefId = parseInt($(this).data('event-person-credential-type-ref-id'));
        let name = $(this).data('event-person-name').name;
        let dept = $(this).data('event-person-department').department;
        let role = $(this).data('event-person-role').role;

        name = name ? name.toUpperCase() : name;
        dept = dept ? dept.toUpperCase() : dept;
        role = role ? role.toUpperCase() : role;

        $("#credential_print_modal_cb_escort").prop('checked', false);
        $("#credential_print_modal_cb_octagon").prop('checked', false);

        //credential_type_ref_id: 7
        let escort = false;
        if (credentialTypeRefId === 7 || credentialTypeRefId === 2 || credentialTypeRefId === 11) {
            escort = true;
            $("#credential_print_modal_cb_escort").prop('checked', true);
        }

        let octagon = $(this).data('event-person-credential-octagon-access');

        this.octoChecked = octagon;
        if (this.octoChecked) {
            $('#credential_print_modal_cb_octagon').prop('checked', true);
        }

        credential.credentialPrintModalPersonName.text(name);

        credential.printIsBulk = false;
        credential.credentialNum = $(this).data('credential-num');
        credential.populateCredentialPrintModal(eventPersonId, personId, credentialPicExt, credentialTypeRefId, name, "", dept, role, octagon, escort, eventJson, () => {
        });
        credential.credentialPrintModal.removeClass('modal__hidden');
        credential.credentialPrintModal.addClass('modal__visible');
    };

    async bulkPrintOnClick() {
        let creds = [];
        let selected = $('input.bulkPrintCheckBox:checked')
        toastr.remove();

        let people = []
        for (const person of selected) {
            let eventJson = $(person).data('event');
            let personId = $(person).data('person-id');
            let eventPersonId = $(person).data('event-person-id');
            let credentialNum = $(person).data('credential-num');
            people.push({eventPersonId: eventPersonId, credentialNum: (credentialNum ? credentialNum : null)});
            let credentialPicExt = $(person).data('person-credential-pic-ext');
            let credentialTypeRefId = parseInt($(person).data('event-person-credential-type-ref-id'));
            let name = $(person).data('event-person-name').name;
            let dept = $(person).data('event-person-department').department;
            let role = $(person).data('event-person-role').role;
            name = name ? name.toUpperCase() : name;
            dept = dept ? dept.toUpperCase() : dept;
            role = role ? role.toUpperCase() : role;
            //credential_octagon_access
            let octo = $(person).data('event-person-credential-octagon-access');
            let lastName = $(person).data('event-person-last-name');

            //credential_type_ref_id: 7
            let escort = false;
            if (credentialTypeRefId === 7 || credentialTypeRefId === 2 || credentialTypeRefId === 11) {
                escort = true;
            }

            credential.credentialPrintModalPersonName.text(name);

            credential.printIsBulk = true;
            credential.populateCredentialPrintModal(eventPersonId, personId, credentialPicExt, credentialTypeRefId, name, lastName, dept, role, octo, escort, eventJson, (cardDetails) => {
                creds.push(cardDetails)

                if (creds.length === selected.length) {
                    let html = ""
                    creds = creds.sort((a, b) => {
                        const collator = new Intl.Collator('en');
                        return collator.compare(a.lastName, b.lastName)
                    })
                    creds.forEach((cred) => {
                        let ref = cred.credentialTypeRefId
                        let wrapperStyle = "wrapper-black"
                        if (ref == 5 || ref == 6 || ref == 7) {
                            wrapperStyle = `wrapper-${credential.tempColor}`
                        } else if (ref == 3 || ref == 4) {
                            wrapperStyle = `wrapper-blue`
                        }else if (ref == 8) {
                            wrapperStyle = `wrapper-red`
                        }
                        let canvas = cred.canvas.toDataURL()
                        html +=
                            `<div class='${wrapperStyle}'>`+
                            "<div class='side1'><img  src='" + canvas + "'  style=''/></div> " +
                            "<div class='side2'><img id='print_img_rotated' class='rotate180' src='" + canvas + "'  style=''/></div></div>"
                    })
                    $('#printable-content').addClass("printable-content-bulk");
                    $('#printable-content').removeClass("printable-content");
                    document.getElementById('printable-content').innerHTML = html;
                    window.print();
                    window.onafterprint = () => {
                        if (credential.printIsBulk && confirm('Mark all as printed?')) {
                            credential.updatePrintTimeBulk(people);
                            $('input.bulkPrintCheckBox:checked').prop("checked", false);

                        }
                    }

                }

            });
        }

    };

    credentialPrintCloseOnClick() {
        credential.credentialPrintModal.removeClass('modal__visible');
        credential.credentialPrintModal.addClass('modal__hidden');
    };

    updatePrintTimeBulk(list) {
        $.ajax({
            url: ('/event_people/0/ajax_update_credential_printed_ts_bulk'),
            method: 'POST',
            data: {event_people: list},
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    for (const ep of list) {
                        $(`#ep_${ep.eventPersonId}_printed_checkmark${ep.credentialNum ? ep.credentialNum : ''}`).html('&check;');
                        $(`#ep_${ep.eventPersonId}_printed_checkmark${ep.credentialNum ? ep.credentialNum : ''}`).removeClass('red').addClass('green');
                    }
                } else {
                    toastr.error('Unable To Mark Row As Printed.');
                }
            },
            error: function (data) {
                toastr.error('Unable To Mark Row As Printed.');
            }
        });
    }


    populateCredentialPrintModal(eventPersonId, personId, credentialPicExt, credentialTypeRefId,
                                 name, lastName = "", dept, org, octagon, escort, eventIn, callback) {
        this.eventPersonId = eventPersonId;
        this.personId = personId;
        this.credentialPicExt = credentialPicExt;
        this.credentialTypeRefId = credentialTypeRefId;
        this.event = eventIn;
        this.eventDate = eventIn.start_date;
        this.eventLocation = eventIn.venue_name;
        this.tempColor = this.event.credential_temp_color ? this.event.credential_temp_color : 'white';
        this.credentialPicHolder.attr('src', '/people/' + personId + '/cred_pic?rand=' + Math.random());

        let pattern = /UFC (\d{3,4})/i;


        if (pattern.test(this.event.name)) {
            this.eventNum = this.event.name.match(pattern)[1];
        }
        let cardDetails = {
            name: '',
            org: '',
            dept: '',
            headshot: '../canvasImages/headshot-placeholder.png',
            location: '',
            date: '',
            credentialTypeRefId: credentialTypeRefId,
            credentialPicExt: credentialPicExt,
            octagon: octagon,
            escort: escort,
            lastName: lastName,
        };
        credential.cardDetails = cardDetails;
        cardDetails.name = name;
        cardDetails.org = org ? org : "";
        cardDetails.dept = dept ? dept : "";
        cardDetails.location = eventIn.venue_name
        this.setup(cardDetails, callback);
    };


    ///// Darin's code
    isCustomCredentialsEvent = $('#custom_credentials_event').data('is-custom-credentials-event');

    accessTypes;
    options
    curOption;
    tempColors
    mediaDate;
    previewButton;
    printButton;
    images;

    canvasCtx;
    canvasS;
    canvasW = 656;
    canvasH = 1019;
    previewContainer = $('#credential_print_modal_canvas_holder');

    locationFont = 'KlavikaWebBoldCond';
    locationSize = 70;
    dateFont = 'KlavikaWebMedCond';
    dateSize = 32;
    nameFont = 'KlavikaWebBoldCond';
    nameSize = 60;
    orgFont = 'KlavikaWebMedCond';
    orgSize = 36;
    imagePath = '../../img';
    permBg = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-bg')}` : '../../canvasImages/permanent-bg.png';
    permSlapBg = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-bg')}` : '../../canvasImages/permanent-slap-bg.png';
    ufcLogo = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-logo')}` : '../../canvasImages/ufc-logo__white.png';
    fightNightLogo = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-logo')}` : '../../canvasImages/ufc-fight-night-logo__white.png';
    dwcsLogo = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-logo')}` : '../../canvasImages/dwcs-logo__white.png';
    slapLogo = this.isCustomCredentialsEvent ? `../../${$('#custom_credentials_event').data('custom-credentials-logo')}` : '../../canvasImages/power-slap-logo__white.png';
    badgeOctagon = '../../canvasImages/octagon-badge.png';
    badgeEscort = '../../canvasImages/escort-badge.png';

    permProps = {
        logoBgFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-logo-bg-color') : '#d30014',
        logoBgW: 218,
        logoBgH: 262,
        logoW: 176,
        headshotW: 396,
        headshotH: 412,
        badgeW: 115,
        badgeH: 115,
        textFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-text-color') : '#FFFFFF'
    };

    tempProps = {
        logoBgFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-logo-bg-color') : '#0e1f1d',
        logoBgW: 218,
        logoBgH: 262,
        logoW: 176,
        headshotW: 396,
        headshotH: 412,
        badgeW: 115,
        badgeH: 115,
        textFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-text-color') : '#FFFFFF'
    };

    mediaProps = {
        logoBgFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-logo-bg-color') : '#0e1f1d',
        logoBgW: 218,
        logoBgH: 262,
        logoW: 176,
        headshotW: 396,
        headshotH: 412,
        textFill: this.isCustomCredentialsEvent ? $('#custom_credentials_event').data('custom-credentials-text-color') : '#FFFFFF'
    };


    loadCanvasImages(urls, type, cardDetails, callback) {

        let imageCount = 1;
        let images = [];
        //load cred image
        const headShotImg = new Image();
        headShotImg.src = this.getCredImageSrc();
        headShotImg.onload = () => {
            images.push(headShotImg)
            // loop through and preload images before attempting to draw them to canvas
            urls.forEach((url) => {
                // create image element and set path
                const image = new Image();
                image.src = `${credential.imagePath}/${url}`;
                // when image loads
                image.onload = () => {
                    imageCount++;
                    // check if all images have loaded

                    if (imageCount === images.length) {
                        if (type === 'perm') {
                            credential.drawPermanentCanvas(cardDetails, images, callback);
                        } else if (type === 'temp') {
                            credential.drawTempCanvas(cardDetails, images, callback);
                        } else if (type === 'media') {
                            credential.drawMediaCanvas(cardDetails, images, callback);
                        }
                    }
                    // add image el to array
                }
                images.push(image);
            });
        };
    }

    drawMediaCanvas(cardDetails, images, callback) {
        let noFloor = false;
        let octagon = cardDetails.octagon;
        let escort = cardDetails.escort

        // process details
        if (cardDetails.credentialTypeRefId === 3 || cardDetails.credentialTypeRefId === 8) noFloor = true;

        // process text inputs
        if (this.eventLocation !== '') cardDetails.location = this.eventLocation.toUpperCase();
        if (this.eventDate !== '') cardDetails.date = this.eventDate.toUpperCase();

        // draw bg
        cardDetails.canvasCtx.drawImage(images[1], 0, 0, this.canvasW, this.canvasH);

        // draw logo bg, if no slap
        if (this.isCustomCredentialsEvent || (this.event.name.toLowerCase().indexOf(' slap ') === -1)) {
            cardDetails.canvasCtx.fillStyle = this.mediaProps.logoBgFill;
            cardDetails.canvasCtx.fillRect((this.canvasW - this.mediaProps.logoBgW) / 2, 0, this.mediaProps.logoBgW, this.mediaProps.logoBgH);
        }
        // draw logo
        let logoH = this.tempProps.logoW * this.getAspectRatio(images[2]);
        if (this.eventNum != null) {
            this.drawNumberedLogo(cardDetails, images[2], this.tempProps.logoW, logoH, this.eventNum);
        } else {
            if (!this.isCustomCredentialsEvent && (this.event.name.toLowerCase().indexOf(' slap ') != -1)) {
                let logoW = this.tempProps.logoW + 20;
                logoH = logoW * this.getAspectRatio(images[2]);
                cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - logoW) / 2, this.tempProps.logoBgH - 45 - logoH, logoW, logoH);
            } else {
                cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - this.tempProps.logoW) / 2, this.tempProps.logoBgH - 20 - logoH, this.tempProps.logoW, logoH);
            }
        }

        // draw headshot
        cardDetails.canvasCtx.drawImage(images[0], (this.canvasW - this.mediaProps.headshotW) / 2, 410, this.mediaProps.headshotW, this.mediaProps.headshotH);

        // draw no floor
        if (noFloor) {
            // draw rect
            cardDetails.canvasCtx.fillStyle = '#ff0101';
            cardDetails.canvasCtx.fillRect(0, 0, 100, this.canvasH);

            // draw text
            cardDetails.canvasCtx.save();
            cardDetails.canvasCtx.textAlign = 'center';
            cardDetails.canvasCtx.textBaseline = 'alphabetic';
            cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
            cardDetails.canvasCtx.rotate(-90 * Math.PI / 180);
            cardDetails.canvasCtx.fillStyle = '#000';
            cardDetails.canvasCtx.fillText('NO FLOOR', 3 - this.canvasH / 2, this.nameSize + 13);
            cardDetails.canvasCtx.fillStyle = '#FFF';
            cardDetails.canvasCtx.fillText('NO FLOOR', -this.canvasH / 2, this.nameSize + 10);
            cardDetails.canvasCtx.restore();
        }

        // draw escort badge
        if (escort) {
            cardDetails.canvasCtx.drawImage(images[3], 487, 20, this.mediaProps.badgeW, this.mediaProps.badgeH);
        }
        // draw octagon badge
        if (octagon && !noFloor) {
            // draw rect
            cardDetails.canvasCtx.fillStyle = '#5af64e';
            cardDetails.canvasCtx.fillRect(0, 0, 100, this.canvasH);

            // draw text
            cardDetails.canvasCtx.save();
            cardDetails.canvasCtx.textAlign = 'center';
            cardDetails.canvasCtx.textBaseline = 'alphabetic';
            cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
            cardDetails.canvasCtx.rotate(-90 * Math.PI / 180);
            cardDetails.canvasCtx.fillStyle = '#000';
            cardDetails.canvasCtx.fillStyle = '#FFF';
            cardDetails.canvasCtx.fillText(((this.event.name.toLowerCase().indexOf(' slap ') != -1) ? 'PHOTOGRAPHER' : 'OCTAGON PHOTO'), -this.canvasH / 2, this.nameSize + 10);
            cardDetails.canvasCtx.restore();

        }

        // draw location
        cardDetails.canvasCtx.textAlign = 'center';
        cardDetails.canvasCtx.textBaseline = 'alphabetic'
        cardDetails.canvasCtx.fillStyle = this.mediaProps.textFill;
        cardDetails.canvasCtx.font = `normal 700 ${this.locationSize}px ${this.locationFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.location, this.canvasW / 2, 335);

        // draw date

        let date = new Date(cardDetails.date);
        let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(utcDate);
        cardDetails.canvasCtx.font = `normal 500 ${this.dateSize}px ${this.dateFont}`;
        cardDetails.canvasCtx.fillText(`${month.toUpperCase()} ${date.getUTCDate()}, ${date.getFullYear()}`, this.canvasW / 2, 380);

        // draw name
        cardDetails.canvasCtx.textAlign = 'center';
        cardDetails.canvasCtx.textBaseline = 'alphabetic'
        cardDetails.canvasCtx.fillStyle = this.mediaProps.textFill;
        cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
        //cardDetails.canvasCtx.fillStyle = '#FFF';
        cardDetails.canvasCtx.fillText(cardDetails.name, this.canvasW / 2, 890);

        // draw org
        cardDetails.canvasCtx.font = `normal 500 ${this.orgSize}px ${this.orgFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.org, this.canvasW / 2, 935);

        // draw dept
        cardDetails.canvasCtx.fillText(cardDetails.dept, this.canvasW / 2, 980);

        // display canvas as image
        this.convertCanvasToImage(cardDetails, callback);
    }

    drawTempCanvas(cardDetails, images, callback) {
        let noFloor = cardDetails.credentialTypeRefId === 5;

        let octagon = cardDetails.octagon;
        let escort = cardDetails.escort

        // process details

        // process text inputs
        if (this.eventLocation !== '') cardDetails.location = this.eventLocation.toUpperCase();
        if (this.eventDate !== '') cardDetails.date = this.eventDate.toUpperCase();

        // draw bg
        cardDetails.canvasCtx.drawImage(images[1], 0, 0, this.canvasW, this.canvasH);

        // draw logo bg, if no slap
        if (this.isCustomCredentialsEvent || (this.event.name.toLowerCase().indexOf(' slap ') === -1)) {
            cardDetails.canvasCtx.fillStyle = this.tempProps.logoBgFill;
            cardDetails.canvasCtx.fillRect((this.canvasW - this.tempProps.logoBgW) / 2, 0, this.tempProps.logoBgW, this.tempProps.logoBgH);
        }
        // draw logo
        let logoH = this.tempProps.logoW * this.getAspectRatio(images[2]);
        if (this.eventNum != null) {
            this.drawNumberedLogo(cardDetails,images[2], this.tempProps.logoW, logoH, this.eventNum);
        } else {
            if (!this.isCustomCredentialsEvent && (this.event.name.toLowerCase().indexOf(' slap ') != -1)) {
                let logoW = this.tempProps.logoW + 20;
                logoH = logoW * this.getAspectRatio(images[2]);
                cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - logoW) / 2, this.tempProps.logoBgH - 45 - logoH, logoW, logoH);
            } else {
                cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - this.tempProps.logoW) / 2, this.tempProps.logoBgH - 20 - logoH, this.tempProps.logoW, logoH);
            }
        }

        // draw headshot
        cardDetails.canvasCtx.drawImage(images[0], (this.canvasW - this.tempProps.headshotW) / 2, 410, this.tempProps.headshotW, this.tempProps.headshotH);

        // draw no floor
        if (noFloor) {
            // draw rect
            cardDetails.canvasCtx.fillStyle = '#ff0101';
            cardDetails.canvasCtx.fillRect(0, 0, 100, this.canvasH);

            // draw text
            cardDetails.canvasCtx.save();
            cardDetails.canvasCtx.textAlign = 'center';
            cardDetails.canvasCtx.textBaseline = 'alphabetic';
            cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
            cardDetails.canvasCtx.rotate(-90 * Math.PI / 180);
            cardDetails.canvasCtx.fillStyle = '#000';
            cardDetails.canvasCtx.fillText('NO FLOOR', 3 - this.canvasH / 2, this.nameSize + 13);
            cardDetails.canvasCtx.fillStyle = '#FFF';
            cardDetails.canvasCtx.fillText('NO FLOOR', -this.canvasH / 2, this.nameSize + 10);
            cardDetails.canvasCtx.restore();
        }

        // draw escort badge
        if (escort) {
            cardDetails.canvasCtx.drawImage(images[3], 487, 20, this.tempProps.badgeW, this.tempProps.badgeH);
        }

        // draw octagon badge
        if (octagon) {
            cardDetails.canvasCtx.drawImage(images[4], 487, (20 + 8 + this.tempProps.badgeH), this.tempProps.badgeW, this.tempProps.badgeH);
        }

        // draw location
        cardDetails.canvasCtx.textAlign = 'center';
        cardDetails.canvasCtx.textBaseline = 'alphabetic'
        cardDetails.canvasCtx.fillStyle = this.tempProps.textFill;
        cardDetails.canvasCtx.font = `normal 700 ${this.locationSize}px ${this.locationFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.location, this.canvasW / 2, 335);

        // draw date
        let date = new Date(cardDetails.date);
        let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(utcDate);
        cardDetails.canvasCtx.font = `normal 500 ${this.dateSize}px ${this.dateFont}`;
        cardDetails.canvasCtx.fillText(`${month.toUpperCase()} ${date.getUTCDate()}, ${date.getFullYear()}`, this.canvasW / 2, 380);

        // draw name
        cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.name, this.canvasW / 2, 890);

        // draw org
        cardDetails.canvasCtx.font = `normal 500 ${this.orgSize}px ${this.orgFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.org, this.canvasW / 2, 935);

        // draw dept
        cardDetails.canvasCtx.fillText(cardDetails.dept, this.canvasW / 2, 980);

        // display canvas as image
        this.convertCanvasToImage(cardDetails, callback);
    }

    drawPermanentCanvas(cardDetails, images, callback) {
        // process details
        let octagon = cardDetails.octagon;
        let escort = cardDetails.escort;
        let official = ((cardDetails.credentialTypeRefId === 9) || (cardDetails.credentialTypeRefId === 12));


        // draw bg
        cardDetails.canvasCtx.drawImage(images[1], 0, 0, this.canvasW, this.canvasH);

        if ([10,11,12].indexOf(cardDetails.credentialTypeRefId) !== -1) { // Slap
            let logoW = this.tempProps.logoW + 20;
            let logoH = logoW * this.getAspectRatio(images[2]);
            cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - logoW) / 2, this.tempProps.logoBgH - 45 - logoH, logoW, logoH);
        } else { // UFC
            // draw logo bg
            cardDetails.canvasCtx.fillStyle = this.permProps.logoBgFill;
            cardDetails.canvasCtx.fillRect((this.canvasW - this.permProps.logoBgW) / 2, 0, this.permProps.logoBgW, this.permProps.logoBgH);
            // draw logo
            let logoH = this.tempProps.logoW * this.getAspectRatio(images[2]);
            cardDetails.canvasCtx.drawImage(images[2], (this.canvasW - this.permProps.logoW) / 2, this.permProps.logoBgH - 20 - logoH, this.permProps.logoW, logoH);
        }

        // draw headshot
        cardDetails.canvasCtx.drawImage(images[0], (this.canvasW - this.permProps.headshotW) / 2, 410, this.permProps.headshotW, this.permProps.headshotH);

        // draw escort badge
        if (escort) {
            cardDetails.canvasCtx.drawImage(images[3], 487, 20, this.permProps.badgeW, this.permProps.badgeH);
        }

        // draw octagon badge
        if (octagon) {
            cardDetails.canvasCtx.drawImage(images[4], 487, (20 + 8 + this.permProps.badgeH), this.permProps.badgeW, this.permProps.badgeH);
        }
        if (official) {
            // draw rect
            cardDetails.canvasCtx.fillStyle = '#003b5e';
            cardDetails.canvasCtx.fillRect(0, 0, 100, this.canvasH);

            // draw text
            cardDetails.canvasCtx.save();
            cardDetails.canvasCtx.textAlign = 'center';
            cardDetails.canvasCtx.textBaseline = 'alphabetic';
            cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
            cardDetails.canvasCtx.rotate(-90 * Math.PI / 180);
            cardDetails.canvasCtx.fillStyle = '#000';
            cardDetails.canvasCtx.fillText('OFFICIAL', 3 - this.canvasH / 2, this.nameSize + 13);
            cardDetails.canvasCtx.fillStyle = '#FFF';
            cardDetails.canvasCtx.fillText('OFFICIAL', -this.canvasH / 2, this.nameSize + 10);
            cardDetails.canvasCtx.restore();
        }

        // draw name
        cardDetails.canvasCtx.textAlign = 'center';
        cardDetails.canvasCtx.textBaseline = 'alphabetic'
        cardDetails.canvasCtx.fillStyle = this.permProps.textFill;
        cardDetails.canvasCtx.font = `normal 700 ${this.nameSize}px ${this.nameFont}`;
        //cardDetails.canvasCtx.fillStyle = '#FFF';
        cardDetails.canvasCtx.fillText(cardDetails.name, this.canvasW / 2, 890);

        // draw org
        cardDetails.canvasCtx.font = `normal 500 ${this.orgSize}px ${this.orgFont}`;
        cardDetails.canvasCtx.fillText(cardDetails.org, this.canvasW / 2, 935);

        // draw dept
        cardDetails.canvasCtx.fillText(cardDetails.dept, this.canvasW / 2, 980);

        // display canvas as image
        this.convertCanvasToImage(cardDetails, callback);
    }

    drawNumberedLogo(cardDetails, logoImage, logoW, logoH, num) {
        cardDetails.canvasCtx.fillStyle = '#FFF';

        // draw logo image
        cardDetails.canvasCtx.drawImage(
            logoImage,
            (this.canvasW - logoW) / 2,
            this.tempProps.logoBgH - 75 - logoH,
            logoW,
            logoH
        );

        // draw rectangle
        let x1 = (this.canvasW - logoW) / 2;
        let x2 = x1 + (logoW * .4);
        let y1 = this.tempProps.logoBgH - 2 - logoH;
        let y2 = y1 + 8;
        cardDetails.canvasCtx.beginPath();
        cardDetails.canvasCtx.moveTo(x1, y1);
        cardDetails.canvasCtx.lineTo(x2 + 3, y1);
        cardDetails.canvasCtx.lineTo(x2, y2);
        cardDetails.canvasCtx.lineTo(x1 - 3, y2);
        cardDetails.canvasCtx.fill();

        // draw event text
        cardDetails.canvasCtx.font = `italic 700 62px ${this.nameFont}`;
        cardDetails.canvasCtx.textAlign = 'right';
        cardDetails.canvasCtx.textBaseline = 'top';
        cardDetails.canvasCtx.fillText(num, (this.canvasW / 2) + (logoW / 2) - 25, y1 - 4);
    }

    getCredImageSrc() {

        if (this.credentialPicExt == null) {
            return '../../canvasImages/headshot-placeholder.png';
        }
        let src = $('#credential_print_modal_cred_pic').attr('src');
        return src;

    }

    createCanvas(cardDetails, callback) {
        let type = 'perm';
        let cred = cardDetails.credentialTypeRefId;
        if (cred == 5 || cred == 6 || cred == 7) {
            type = 'temp'
        } else if (cred == 3 || cred == 4 || cred == 8) {
            type = 'media'
        }

        let imageUrls = [];

        this.canvasS = window.devicePixelRatio;
        this.canvas = '';

        // clear previous image, if any
        this.previewContainer.innerHTML = '';

        // create canvas element
        cardDetails.canvas = document.createElement('canvas');
        cardDetails.canvasCtx = cardDetails.canvas.getContext('2d');
        cardDetails.canvas.id = 'previewCanvas';
        cardDetails.canvas.width = Math.floor(this.canvasW * this.canvasS);
        cardDetails.canvas.height = Math.floor(this.canvasH * this.canvasS);
        cardDetails.canvasCtx.scale(this.canvasS, this.canvasS);

        // set values to user vars


        // build list of external images we need
        if (type === 'perm') {
            let bg = this.permBg;
            let logo = this.ufcLogo;
            if ([10,11,12].indexOf(cardDetails.credentialTypeRefId) !== -1) {
                bg = this.permSlapBg;
                logo = this.slapLogo;
            }
            // set array of urls to preload
            imageUrls = [bg, logo, this.badgeEscort, this.badgeOctagon];
        } else if (type === 'temp') {
            // process colors
            let color = this.tempColor;

            if (!this.isCustomCredentialsEvent && (this.event.name.toLowerCase().indexOf(' slap ') === -1) && ((color === 'yellow') || (color === 'white'))) {
                this.tempProps.textFill = '#000000';
            } else if (!this.isCustomCredentialsEvent) {
                this.tempProps.textFill = '#FFFFFF';
            }
            // process logos
            let logo = this.fightNightLogo;
            if (this.event.name.toLowerCase().indexOf(' slap ') != -1) {
                logo = this.slapLogo;
            } else if (this.event.name.toLowerCase().indexOf('dwcs') != -1) {
                logo = this.dwcsLogo;
            } else if (this.eventNum != null) {
                logo = this.ufcLogo;
            }
            // process bgs
            let bg = `../../canvasImages/temp-bg-${color}.png`;
            if (this.isCustomCredentialsEvent) {
                bg = `../../${$('#custom_credentials_event').data('custom-credentials-bg')}`;
            } else if (this.event.name.toLowerCase().indexOf(' slap ') != -1) {
                bg = `../../canvasImages/power-slap-bg__${color}.png`;
            }
            // set array of urls to preload
            imageUrls = [bg, logo, this.badgeEscort, this.badgeOctagon];
        } else if (type === 'media') {
            // process colors
            let color = 'blue';
            if (cardDetails.credentialTypeRefId === 8) {
                color = 'red';
            }
            // process logos
            let logo = this.fightNightLogo;
            if (this.event.name.toLowerCase().indexOf(' slap ') != -1) {
                logo = this.slapLogo;
            } else if (this.event.name.toLowerCase().indexOf('dwcs') != -1) {
                logo = this.dwcsLogo;
            } else if (this.eventNum != null) {
                logo = this.ufcLogo;
            }
            // process bgs
            let bg = `../../canvasImages/media-bg-${color}.png`;
            if (this.isCustomCredentialsEvent) {
                bg = `../../${$('#custom_credentials_event').data('custom-credentials-bg')}`;
            } else if (this.event.name.toLowerCase().indexOf(' slap ') != -1) {
                bg = `../../canvasImages/power-slap-bg__${color}.png`;
            }
            // set array of urls to preload
            imageUrls = [bg, logo, this.badgeEscort, this.badgeOctagon];
        }
        // add directory to urls
        this.addPathToURLs(imageUrls);

        // load those images
        this.loadCanvasImages(imageUrls, type, cardDetails, callback);
    }

    convertCanvasToImage(cardDetails, callback) {
        // convert canvas to image and add to page
        let image = new Image();

        // set source of image
        image.src = cardDetails.canvas.toDataURL();

        image.width = this.canvasW;
        image.height = this.canvasH;

        image.setAttribute('alt', 'UFC Access Card Preview');
        image.setAttribute('id', 'canvasImage')

        image.onload = function () {
            $('#credential_print_modal_canvas_holder').empty();
            credential.previewContainer.appendChild(image);
            credential.canvas = cardDetails.canvas;
            callback(cardDetails);
        }

    }

    getAspectRatio(image) {
        return image.height / image.width;
    }

    addPathToURLs(array) {
        let val;
        for (let i = 0; i < array.length; i++) {
            val = array[0];
            array[0] = `${this.imagePath}/${val}`;
        }
    }

    getAccessType() {
        let type;

        for (let i = 0; i < this.accessTypes.length; i++) {
            if (this.accessTypes[i].checked) {
                type = this.accessTypes[i].value;
                break;
            }
        }

        return type;
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    setup(cardDetails, callback) {
        this.previewButton = document.querySelector('#credential_print_modal_reload');
        this.printButton = document.querySelector('#credential_print_modal_print');
        this.previewContainer = document.querySelector('#credential_print_modal_canvas_holder');
        // set up vars
        this.options = [];

        // set initial value of options element
        this.curOption = this.options[0];

        // add click handler for preview button
        // this.previewButton.addEventListener('click', (e) => {
        //   credential.createCanvas();
        // });
        credential.canvas = cardDetails.canvas;
        this.printButton.onclick = credential.print;

        // create initial canvas view
        this.createCanvas(cardDetails, callback);
    }
    selectAllPrint(){
            if ($('#select-all-print').is(':checked')) {
                $('input.bulkPrintCheckBox').prop('checked',true);
            } else {
                $('input.bulkPrintCheckBox:checked').prop("checked", false);
            }
    }

    print() {
        let imgURL = credential.canvas.toDataURL();
        $('#printable-content').addClass("printable-content");
        $('#printable-content').removeClass("printable-content-bulk");

        let ref= credential.cardDetails.credentialTypeRefId
        let wrapperStyle = "wrapper-black"
        if (ref == 5 || ref == 6 || ref == 7) {
            wrapperStyle = `wrapper-${credential.tempColor}`
        } else if (ref == 3 || ref == 4) {
            wrapperStyle = `wrapper-blue`
        }else if (ref == 8) {
            wrapperStyle = `wrapper-red`
        }


        document.getElementById('printable-content').innerHTML = `<div class='${wrapperStyle}'>` +
            "<div class='side1'><img src='" + imgURL + "'  style=''/></div> " +
            "<div class='side2'><img class='rotate180' src='" + imgURL + "'  style=''/></div> " +
            "</div>";

        window.print();
        credential.credentialPrintCloseOnClick();

        // Update printed timestamp
        $.ajax({
            url: credential.eventPersonId ? ('/event_people/' + credential.eventPersonId + '/ajax_update_credential_printed_ts') : ('/people/' + credential.personId + '/ajax_update_credential_printed_ts'),
            data: {credential_num: credential.credentialNum},
            method: 'POST',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    if (credential.eventPersonId) {
                        $(`#ep_${credential.eventPersonId}_printed_checkmark${credential.credentialNum ? credential.credentialNum : ''}`).html('&check;');
                        $(`#ep_${credential.eventPersonId}_printed_checkmark${credential.credentialNum ? credential.credentialNum : ''}`).removeClass('red').addClass('green');
                    } else {
                        $(`#p_${credential.personId}_printed_checkmark${credential.credentialNum ? credential.credentialNum : ''}`).html('&check;');
                        $(`#p_${credential.personId}_printed_checkmark${credential.credentialNum ? credential.credentialNum : ''}`).removeClass('red').addClass('green');
                    }
                } else {
                    toastr.error('Unable To Mark Row As Printed.');
                }
            },
            error: function (data) {
                toastr.error('Unable To Mark Row As Printed.');
            }
        });

        return;
    }
}


export let credential = new Credential();
